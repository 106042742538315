import { DEFAULT_CDN_URL, FEAASCDNComponentParams, fetchAndRevalidateComponent, parseComponentSource } from '../cdn.js'
import { renderDOMContent, renderDOMElement } from '../dom/rendering.js'
import { loadStylesheetAllowStale } from '../headless.js'
import { ControlledPromise } from '../utils/promise.js'
import { DataOptions, DataSettings } from '../utils/settings.js'
import { FEAASElement } from './FEAASElement.js'

export function FEAASComponentsProps(element: FEAASElement) {
  let data: any
  const src = element.getAttribute('src')
  if (src) {
    const parsed = parseComponentSource(src)
    if (!parsed) throw new Error(`Could not parse FEAAS Component source: ${src}`)
    var { cdn, library, component, version, revision } = parsed
  }

  const dataValue = element.getAttribute('data')

  if (dataValue != null && dataValue != '') {
    try {
      data = typeof dataValue == 'string' ? JSON.parse(dataValue) : {}
    } catch (e) {
      console.error(e)
    }
  }

  const fetch = element.getAttribute('fetch')
  return {
    data: (data || {}) as DataOptions,
    cdn: element.getContextAttribute('cdn') ?? cdn ?? DEFAULT_CDN_URL,
    library: element.getAttribute('library') ?? library,
    component: element.getAttribute('component') ?? component,
    version: element.getAttribute('version') ?? version ?? 'responsive',
    revision: (element.getAttribute('revision') ?? revision ?? 'published') as FEAASCDNComponentParams['revision'],
    hostname: element.getAttribute('hostname'),
    template: element.getAttribute('template'),
    instance: element.getAttribute('instance'),
    editable: element.getAttribute('editable') != null,
    suspended: element.getAttribute('suspended') != null,
    lastModified: element.getAttribute('last-modified'),
    fetch:
      fetch == 'false'
        ? []
        : fetch == null || fetch == 'true'
        ? ['data', 'template', 'stylesheet']
        : fetch.split(/(\s+|\-)+/g).filter(Boolean)
  }
}

export type FEAASComponentsProps = ReturnType<typeof FEAASComponentsProps>

/**
 * - Initialization lifecycle:
 * - - `set(props)`
 * - - `scheduleRender()` - any attribute change causes render to schedule to next frame
 * - - `whenRendered.restart()` - rendering promise is reset and will resolve after render
 * - - `advance()` - called on next frame
 * - - `if (isReadyToLoad()) => boolean` - check if component has all necessary properties to load
 * - - `load() => payload` => component is loaded
 * - - `onLoad?(payload)` => invoke loaded callback for cached version of a component
 * - - `advance()` => going further in state machine
 * - - `flush()` => flushes all react changes if needed
 * - - `update()` => component-specific method (or react) to render changes to dom
 * - - `render(payload, props)` => render means react
 * - - `whenRendered.resolve()` - rendering promise is resolved after rendering
 * - - IF CACHE WAS STALE - FETCH AND RENDER AGAIN
 * - - `fetchByPassingCache()` - load up-to-date version of a component
 * - - `onLoad(newPayload) `
 * - - `advance()` => going further in state machine
 * - - `flush()` => flushes all react changes if needed
 * - - `update()` => component-specific method (or react) to render changes to dom
 * - - `render(payload, props)` => render means react
 * - - `whenRendered.resolve()` - rendering promise is resolved after rendering
 * - - `whenLoaded.resolved()` - resolved after loading of up to date component
 */
export class FEAASComponent extends FEAASElement<FEAASComponentsProps, string> {
  readyData: any = {}
  whenDataReady = ControlledPromise<any>(
    (data) => (this.readyData = data),
    (e) => this.onError(e)
  )

  static observedAttributes = [
    'library',
    'component',
    'version',
    'revision',
    'hostname',
    'cdn',
    'template',
    'data',
    'instance',
    'editable',
    'with-stylesheet'
  ]

  defaultProps = {
    cdn: DEFAULT_CDN_URL,
    revision: 'published',
    version: 'responsive',
    editable: false,
    suspended: false,
    data: {}
  } as Partial<FEAASComponentsProps>

  needsRefresh: boolean
  needsToFetchTemplate: boolean

  setData(data: DataOptions) {
    this.set({ data })
  }

  getProps() {
    return FEAASComponentsProps(this)
  }

  isJSONAttribute(prop: keyof FEAASComponentsProps) {
    return prop == 'data'
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    if (oldValue === newValue) return

    // force component to be refreshed
    if (name == 'library' || name == 'component' || name == 'revision' || name == 'version') {
      this.payload = undefined
      if (this.alreadyRendered) {
        this.needsRefresh = true
        this.needsToFetchTemplate = true
      }
    }

    super.attributeChangedCallback(name, oldValue, newValue)

    if (name == 'data') {
      this.needsRefresh = true
      if (this.props.fetch.includes('data') || this.alreadyRendered || !DataSettings.hasDataSettings(this.props.data)) {
        this.fetchData()
      }
    }
  }

  // Reset readyData and perform a new fetch.
  async fetchData() {
    this.readyData = undefined

    this.whenDataReady = this.whenDataReady.restart()

    const data = await DataSettings.fetch(this.props.data)

    const alreadyRendered = this.alreadyRendered

    await this.whenDataReady.resolve(data)
    if (alreadyRendered) {
      this.advance()
    }
  }

  getEditor(): any {
    return (document.querySelector('feaas-context') as FEAASElement)?.refs.editor
  }

  constructor() {
    super()

    const data = this.getAttribute('data')
    if (!this.getAttribute('data')) {
      this.whenDataReady.resolve({})
    }

    this.addEventListener('click', () => {
      if (this.props.editable) {
        this.edit()
      }
    })
  }

  edit() {
    const editor = this.getEditor()
    if (editor?.setTarget(this)) {
      editor.open()
      return true
    }
  }

  /* Check if component is a repeated clone, and return the original */
  getOriginal() {
    const scope = this.getAttribute('data-path-scope')
    if (!scope) return
    for (var current = this as Element; (current = current.previousElementSibling); ) {
      if (current.getAttribute('data-path-scope') == scope) {
        var last = current
      } else {
        break
      }
    }
    return last as FEAASComponent
  }

  isReadyToLoad() {
    return (
      super.isReadyToLoad() && this.props.library != null && this.props.component != null && this.props.version != null
    )
  }

  onLoad = (html: string) => {
    this.payload = html
    this.advance()
  }

  async load() {
    // defer loading to original element
    if (this.getOriginal()) return

    const advanceIfEverythingIsReady = () => {
      // html is not loaded
      if (this.payload == null) return

      // shared stylesheet is still being loaded
      if (stylesheetPromise) return

      // data is still loading
      if (!this.readyData) return

      this.onLoad(this.payload)
    }

    if (this.props.fetch.includes('stylesheet')) {
      var stylesheetPromise = loadStylesheetAllowStale({ ...this.props }).then(() => {
        stylesheetPromise = null
        advanceIfEverythingIsReady()
      })
    }

    if (this.props.fetch.includes('template') || this.needsToFetchTemplate) {
      this.needsToFetchTemplate = false
      var templatePromise = fetchAndRevalidateComponent(this.props, (html) => {
        this.payload = html
        advanceIfEverythingIsReady()
      })
    } else {
      this.payload = this.props.template ?? this.innerHTML
    }

    return Promise.all([
      stylesheetPromise,
      this.whenDataReady.then(() => advanceIfEverythingIsReady()),
      templatePromise
    ]).then(() => this.payload)
  }

  alreadyRendered: boolean

  flush() {
    if (!this.readyData) return

    super.flush()
  }

  update() {
    this.alreadyRendered = true
    // avoid unnecessary rendering if hydration was disabled
    if (
      !this.props.fetch.includes('data') &&
      !this.props.fetch.includes('template') &&
      this.firstElementChild &&
      !this.needsRefresh
    )
      return
    if (Array.from(this.children).filter((c) => c.tagName != 'BR').length > 0 && !this.needsRefresh) {
      renderDOMElement(this, this.readyData)
    } else {
      this.needsRefresh = false
      // defer rendering to original element
      if (this.getOriginal()) {
        return
      }
      renderDOMContent(this, this.payload, this.readyData)
    }
  }
}

type FetchKeyword = 'data' | 'template' | 'stylesheet'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'feaas-component': {
        data?: any
        class?: string
        suppressHydrationWarning?: boolean
        template?: string
        instance?: string
        editable?: boolean
        children?: any
        dangerouslySetInnerHTML?: { __html: string }
        'last-modified'?: string
        fetch?:
          | `${FetchKeyword} ${FetchKeyword} ${FetchKeyword}`
          | `${FetchKeyword} ${FetchKeyword}`
          | `${FetchKeyword}`
          | ''
      } & (
        | {
            /**
             * @deprecated Use cdn instead.
             */
            hostname?: string
            cdn?: string
            library: string
            component: string
            version?: string
            revision?: FEAASComponentsProps['revision']
          }
        | { src: string }
        | { template: string }
      )
    }
  }
}
FEAASComponent.register('feaas-component')

export { FEAASComponent as Component, FEAASComponentsProps as ComponentProps }
