import {
  FEAASCDNStylesheetProps,
  fetchAndRevalidateStylesheet,
  getStylesheetURL,
  ValidatableStylesheetCallback
} from '../cdn.js'

export function renderStylesheet(props: FEAASCDNStylesheetProps, style?: HTMLStyleElement) {
  renderStylesheetPromise(props, (style ||= document.createElement('style')))
  return style
}

export function renderStylesheetPromise(props: FEAASCDNStylesheetProps, stylesheet?: HTMLStyleElement) {
  stylesheet ||= document.createElement('style')
  return fetchAndRevalidateStylesheet(props, (cssText, phase) => {
    stylesheet.textContent = cssText
  }).then(() => stylesheet)
}

const validatedStylesheets = new WeakSet<HTMLStyleElement | HTMLLinkElement>()
export function loadStylesheet(props: FEAASCDNStylesheetProps, callback?: ValidatableStylesheetCallback) {
  var foundStylesheet: HTMLStyleElement | HTMLLinkElement
  var writableStylesheet: HTMLStyleElement

  if (foundStylesheet && validatedStylesheets.has(foundStylesheet)) {
    return Promise.resolve(foundStylesheet)
  }

  return fetchAndRevalidateStylesheet(props, (cssText, phase) => {
    foundStylesheet ||= findStylesheet(props)
    // dont need to write stylesheet for cached state if we already found a stylesheet
    if (!(phase == 'initial' && foundStylesheet)) {
      writableStylesheet ||=
        !foundStylesheet || foundStylesheet.tagName == 'LINK' ? createStylesheet(props) : foundStylesheet
      writableStylesheet.textContent = cssText
    }
    callback?.(cssText, phase)
  }).then(() => foundStylesheet || writableStylesheet)
}

export function loadStylesheetAllowStale(props: FEAASCDNStylesheetProps) {
  return new Promise((resolve, reject) => {
    loadStylesheet(props, resolve).catch(reject)
  })
}

export function findStylesheet(props: FEAASCDNStylesheetProps) {
  return (document.head.querySelector(`style[data-href="${getStylesheetURL(props)}"]`) ||
    document.querySelector(`link[rel="stylesheet"][href="${getStylesheetURL(props)}"]`)) as
    | HTMLStyleElement
    | HTMLLinkElement
}

export function createStylesheet(props: FEAASCDNStylesheetProps) {
  const url = getStylesheetURL(props)
  const stylesheet = document.createElement('style')
  stylesheet.setAttribute('data-href', url)
  document.head.appendChild(stylesheet)
  return stylesheet
}
