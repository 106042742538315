import { queryObject } from './data.js'

export type DataSourceId = string

// Fully resolved data - object or array
export type Data = Record<string, any> | any[]

// Data keyed by datasource id
export type DataScopes = Data | Record<DataSourceId, Data>

// Data settings is json object representing fetch call
export type DataSettings = ReturnType<typeof DataSettings>

// Data options/samples indexed by id
export type DataOptionsById = Record<DataSourceId, DataSettings | Data>

// Data options combines of the above: data, settings, plain or keyed by datasource id
export type DataOptions = Data | DataSettings | DataOptionsById

/**
 * Cleans the provided settings object by removing unnecessary properties that match the defaults that will be re-added
 * back through normalizations. This method is used for code generated for the user, neant to make it look neater.
 *
 * @param {any} settings  - The settings object to be cleaned.
 * @returns {Object} - The cleaned settings object.
 */
export function clean(settings: any) {
  const { params, method, headers, body, url } = DataSettings(settings)
  var cleanSettings = { url } as Partial<DataSettings>
  var cleanHeaders = { ...headers }
  if (headers['Content-Type'] === 'application/json') delete cleanHeaders['Content-Type']
  if (headers['Accept'] === 'application/json') delete cleanHeaders['Accept']
  if (Object.keys(cleanHeaders).length > 0) {
    cleanSettings.headers = cleanHeaders
  }
  if (method !== 'GET') {
    cleanSettings.method = method
  }
  if (!(method === 'GET' || method === 'HEAD' || Object.keys(body).length === 0)) {
    cleanSettings.body = body
  }
  if (Object.keys(params).length > 0) {
    cleanSettings = { ...cleanSettings, params }
  }
  return cleanSettings
}

/**
 * Checks if the provided data is of type DataSettings by checking if all keys are recognized as settings keys.
 *
 * @param {any} data  - The data to be checked.
 * @returns {boolean} - True if the data is of type DataSettings, false otherwise.
 */
export const isDataSettings = (data: any): data is DataSettings => {
  if (!data || typeof data.url != 'string') return false

  for (var property in data) {
    if (
      property == 'body' ||
      property == 'params' ||
      property == 'headers' ||
      property == 'url' ||
      property === 'method' ||
      (property == 'jsonpath' && typeof data[property] == 'string')
    ) {
      continue
    }

    return false
  }

  return true
}

/**
 * Converts settings object into fetch function arguments.
 *
 * @param {any} settings  - The settings object.
 * @returns {Array} - An array containing URL and options objects as fetch function arguments.
 */
export function toFetchArguments(settings?: any): Parameters<typeof fetch> {
  const { url, headers, params, method, body: payload } = DataSettings(settings)

  let body = undefined

  const contentType = headers['Content-Type']

  if (method != 'GET' && method != 'HEAD') {
    if (contentType === 'application/json') body = JSON.stringify(payload)
    if (contentType === 'multipart/form-data')
      body = Object.keys(payload).reduce((form, key) => {
        form.append(key, (payload as any)[key])
        return form
      }, new FormData())
    if (contentType === 'application/x-www-form-urlencoded') body = new URLSearchParams(payload)
  }

  const query = Object.keys(params).length ? `?${new URLSearchParams(params)}` : ''

  return [
    `${url}${query}`,
    {
      headers,
      body,
      method
    }
  ]
}

/**
 * Creates a DataSettings object based on the provided settings. These settings are used later to fetch data for the
 * component. It's essentially fetch options with url.
 *
 * @param {any} settings  - The settings object.
 * @returns {DataSettings} - The DataSettings object.
 */
export function DataSettings(settings: any) {
  const headers = {} as any
  for (const key in settings?.headers || {}) {
    if (settings.headers.hasOwnProperty(key)) {
      const headerCase = key.replace(/^[a-z]|\-[a-z]/g, (m) => m.toUpperCase())
      headers[headerCase] = settings.headers[key]
    }
  }
  if (!headers.Accept) headers['Accept'] = 'application/json'
  return {
    params: (settings?.params || {}) as Record<string, any>,
    headers: headers as Record<string, string>,
    jsonpath: String(settings?.jsonpath || '$'),
    method: (settings?.method || 'GET').toUpperCase() as
      | 'GET'
      | 'POST'
      | 'OPTIONS'
      | 'PUT'
      | 'DELETE'
      | 'UPDATE'
      | 'HEAD',
    body: (settings?.body || {}) as Record<string, any>,
    url: String(settings?.url || '')
  }
}
/**
 * Fetches data based on the provided settings. It is expected that reesponse is json (forced by Accept header)
 *
 * @param {DataSettings} settings  - The data settings.
 * @returns {Promise<Data>} - A promise that resolves to the fetched data.
 */
const fetchDataSettings = async (settings: DataSettings): Promise<Data> => {
  try {
    const [url, options] = toFetchArguments(settings)
    const response = await DataSettings.fetchImplementation(url, {
      ...options,
      // next.js only allows lowercase method
      method: options.method.toLowerCase()
    })
    const json = await response.json()
    if (settings.jsonpath && settings.jsonpath != '$') {
      return queryObject(json, settings.jsonpath)
    }
    return json
  } catch (e) {
    return {}
  }
}
/**
 * Fetches data based on the provided data options. Options is a union of different types, settngs, data or
 * datasource-id keyed settings/data. If the function doesnt find settings in the object, it returns it as is. It
 * supports mixing of static and fetched data. If multiple data settings are found, requests are made in parallel.
 *
 * @param {DataOptions} data  - The data options.
 * @returns {Promise<DataScopes>} - A promise that resolves to the fetched data.
 */
export async function fetchData(data: DataOptions): Promise<DataScopes> {
  if (!data) return data

  if (isDataSettings(data)) return fetchDataSettings(data)

  const keys = Object.keys(data)

  const allRequests = await Promise.all(
    keys
      .map((key: keyof typeof data) => (isDataSettings(data[key]) ? fetchDataSettings(data[key]) : null))
      .filter(Boolean)
  )

  return keys.reduce(
    (obj, key: keyof typeof data) => ({
      ...obj,
      [key]: isDataSettings(data[key]) ? allRequests.shift() : data[key]
    }),
    {}
  )
}

export function hasDataSettings(data: DataOptions) {
  if (!data) return false
  return isDataSettings(data) || Object.values(data).some(isDataSettings)
}

DataSettings.clean = clean
DataSettings.toFetchArguments = toFetchArguments
DataSettings.fetchOne = fetchDataSettings
DataSettings.fetch = fetchData
DataSettings.isDataSettings = isDataSettings
DataSettings.hasDataSettings = hasDataSettings
DataSettings.fetchImplementation = (...args: Parameters<typeof fetch>) => fetch(...args)
