import type { JSXElementConstructor, ReactElement, ReactNode } from 'react'
import type ReactDOMClient from 'react-dom/client'
import type ReactDOM from 'react-dom'
import type React from 'react'
import { ControlledPromise } from '../utils/promise.js'

export type OptionalExcept<T, K extends keyof T> = Pick<T, K> & Partial<Omit<T, K>>

// Shim in case it's required in node.js environment
var WebComponent =
  typeof HTMLElement == 'undefined'
    ? // @ts-ignore
      typeof windowJSDOM != 'undefined'
      ? // @ts-ignore
        (windowJSDOM.HTMLElement as typeof HTMLElement)
      : (class {} as unknown as typeof HTMLElement)
    : HTMLElement

export interface ReactPayload {
  React: typeof React
  ReactDOM: typeof ReactDOM
  ReactDOMClient: typeof ReactDOMClient
}

declare global {
  interface DocumentEventMap {
    feaasMount: Event
    feaasUnmount: Event
    feaasLoad: Event
    feaasError: CustomEvent<Error>
  }
}

/**
 * FEAASElement is a superclass for FEAAS components with optional react support.
 *
 * Initialization lifecycle:
 *
 * - `set(props)`
 * - `scheduleRender()` - any attribute change causes render to schedule to next frame
 * - `whenRendered.restart()` - rendering promise is reset and will resolve after render
 * - `advance()` - called on next frame
 * - `if (isReadyToLoad()) => boolean` - check if component has all necessary properties to load
 * - `load() => payload` => component is loaded
 * - `onLoad?(payload)` => invoke loaded callback
 * - `advance()` => going further in state machine
 * - `flush()` => flushes all react changes if needed
 * - `update()` => component-specific method (or react) to render changes to dom
 * - `render(payload, props)` => render means react
 * - `whenRendered.resolve()` - rendering promise is resolved after rendering
 * - `whenLoaded.resolved()` - resolved after loading and rendering
 */
export class FEAASElement<Props = any, Payload = any> extends WebComponent {
  static observedAttributes: string[]

  defaultProps = {} as Partial<Props>

  /** Return attributes as parsed values */
  getProps(context?: HTMLElement): any {
    return {}
  }

  /** Properties parsed in current tick */
  props: Props
  overrides: Props

  /** Timer until next tick */
  nextRender: number

  /** Is/was component in the dom? */
  connected = false

  refs: Record<string, FEAASElement> = {}
  descendants: FEAASElement[] = []

  /** Result of lazy loading */
  payload: Payload
  whenLoaded = ControlledPromise<Payload>((payload) => {
    this.payload = payload
  })

  constructor() {
    super()
    this.addEventListener('feaasMount', this)
    this.addEventListener('feaasUnmount', this)
  }

  /** Attaches shadow root if getShadowRootOptions any settings, or uses element itself */
  getRoot(): FEAASElement | HTMLElement | ShadowRoot {
    return this
  }

  /** Attribute changes trigger props parsing and schedules re-rendering */
  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    this.set()
  }

  /** Components parses its attributes when connected, and schedules rendeirng */
  connectedCallback() {
    this.mount()
  }
  disconnectedCallback() {
    this.unmount()
  }
  /**
   * Web components are initialized depth-first, making child elements render before its parents. This could here
   * attempts to avoid this and inverts the initialization logic, so that children are initialized in the context of its
   * already-initialized parents.
   *
   * FEAAS components dispatch `feaasMount` event that allows components to store references to each other. In addition
   * it allows external scripts to listen for initialization of FEAAS components
   */
  mount() {
    try {
      const parent = this.getParent() as FEAASElement
      // Allow parent component to initialize first
      if (parent && !parent.connected) return
      if (this.connected) return
      this.connected = true
      this.log('FEAAS: Mount', this.tagName, this)
      this.dispatchEventIndirectly(new Event('feaasMount', { bubbles: true, composed: true }))
      this.getElements().map((element: FEAASElement) => element.mount?.())
      this.set()
    } catch (e) {
      this.onError(e)
    }
  }

  dispatchEventIndirectly(event: Event) {
    this.dispatchEvent(event)
    const parent = this.getParent() as FEAASElement

    if (parent && !parent.contains(this)) {
      Object.defineProperty(event, 'target', { value: this, writable: false })
      Object.defineProperty(event, 'composedPath', { value: () => [this], writable: false })
      parent.handleEvent(event)
    }
  }

  isJSONAttribute(attribute: keyof Props) {
    return false
  }

  log(...args: any) {
    const format = args[0]

    if (
      (typeof location != 'undefined' && location?.hostname.startsWith('components-')) ||
      localStorage['Sitecore.Components.Debug']
    )
      console.log(
        format +
          ' ' +
          args
            .slice(1)
            .map((arg: any) => {
              return arg && typeof arg == 'object' ? '%O' : '%s'
            })
            .join(' '),
        ...args.slice(1)
      )
  }

  /** Nothing special happens on unmount, except setting a flag */
  unmount() {
    if (!this.connected) return
    this.log('FEAAS: Unmount', this)
    this.dispatchEventIndirectly(new Event('feaasUnmount', { bubbles: true, composed: true }))
    // If a component is react component, we need to re-render tree so that React can unmount it from VDOM
    // so observers can be removed
    if (this.connected && this.initialized && this.render) {
      this.update()
    }
    this.cancelRender()
    this.connected = false
    // If a component is react component, we need to re-render tree so that React can unmount
    // it from VDOM, removing the observers
    if (this.initialized && this.render) {
      this.update()
    }
  }

  getParent(onlyFeaas = true) {
    for (
      var p: HTMLElement = this;
      (p = p.parentElement || ((p.parentNode instanceof ShadowRoot ? p.parentNode.host : null) as HTMLElement));

    ) {
      if (!onlyFeaas || p.tagName.startsWith('FEAAS-')) return p
    }
    // feaas context anywhere in the dom acts as a root element
    if (this.tagName != 'FEAAS-CONTEXT') return document.querySelector('feaas-context')
  }

  getElements(root: HTMLElement | ShadowRoot = this.getRoot(), onlyFeaas = true): HTMLElement[] {
    return Array.from(root.querySelectorAll('*'))
      .map(
        (el) =>
          [
            !onlyFeaas || el.tagName.startsWith('FEAAS-') ? el : null,
            el.shadowRoot ? this.getElements(el.shadowRoot, onlyFeaas) : null
          ] as HTMLElement[]
      )
      .flat()
      .filter(Boolean)
  }

  /** Catch-all event handler that dispatches events to callback */
  handleEvent(event: Event) {
    const target = event.composedPath()[0] as FEAASElement
    switch (event.type) {
      case 'feaasMount':
        if (target != this) this.nestedCallback(target)
        break
      case 'feaasUnmount':
        if (target != this) this.unnestedCallback(target)
        break
    }
  }

  /** Act on children element being removed */
  unnestedCallback(target: FEAASElement) {
    const index = this.descendants.indexOf(target)
    if (index == -1) return
    this.log('FEAAS: Unnest', this, target)
    this.descendants.splice(index, 1)
    this.refs[target.getComponentName()] = null
    if (target.refs.parent == this) target.refs.parent = null
    if (target.refs.top == this) {
      target.refs.top = target.refs.parent
    }
  }

  /** Act on children element being added */
  nestedCallback(target: FEAASElement): boolean | void {
    const index = this.descendants.indexOf(target)
    if (index != -1) return
    this.descendants.push(target)
    this.log('FEAAS: Nest', this, target)
    this.refs[target.getComponentName()] = target
    target.refs.top = this
    target.refs.parent ||= this
    if (target.render) {
      for (var p: FEAASElement = target.refs.parent; p; p = p.refs.parent) {
        if (p.render) {
          target.refs.parentReact ||= p
          target.refs.topReact = p
        }
      }
    }
  }

  getComponentName() {
    return this.tagName.replace('FEAAS-', '').toLowerCase()
  }

  /**
   * Check if lazy component is ready to be loaded. For example a component may stay unloaded unless certain attributes
   * are set
   */
  isReadyToLoad() {
    return this.payload === undefined && this.getAttribute('hidden') == null
  }

  /** Generic error handler */
  onError(error: Error, where: string = 'uncaught', handled = false) {
    const event = new CustomEvent('feaasError', { bubbles: true, composed: true, detail: error })
    this.dispatchEventIndirectly(event)
    this.whenLoaded.reject(error)
    if (!event.defaultPrevented && !handled) {
      this.whenRendered.reject(error)
      try {
        this.unmount()
      } catch (e) {}
      return true
    }
    return false
  }

  shouldUpdateOnLoad() {
    return true
  }

  onLoad: (payload: Payload) => void

  /** Load lazy component & render */
  async advance() {
    try {
      if (this.payload === undefined) {
        if (!this.isReadyToLoad()) return
        this.payload = null
        this.dispatchEventIndirectly(new Event('feaasLoad', { bubbles: true, composed: true }))
        const loaded = await this.load().catch((e) => {
          this.payload = undefined
          throw e
        })

        this.whenLoaded.resolve(loaded)

        // Allow component to handle the post-loading
        if (this.onLoad) {
          return
        }
      } else if (this.payload == null) {
        return
      }

      this.flush()
      this.whenRendered.resolve(this)
    } catch (e) {
      this.onError(e)
    }
  }

  flush() {
    if (!this.connected) return
    const flushSync = (this.payload as ReactPayload)?.ReactDOM?.flushSync
    if (flushSync) {
      flushSync(() => this.update())
    } else {
      this.update()
    }
  }

  /** Invoke preloading logic that needs to finish before component can render */
  load() {
    return Promise.resolve(null)
  }

  /**
   * Component-specific logic that updates the DOM. Default render implementation assume react rendering, in that case
   * the component needs to provide React & ReactDOM references in its payload. Web component redefine its render to not
   * use react at all
   */
  update(): any {
    //this.log('FEAAS: React', this.tagName, this.payload)
    if (this.payload == null) return
    if (!this.render) return
    const { React, ReactDOM, ReactDOMClient } = (this.payload as ReactPayload) || {}
    if (!React) throw new Error(`${this.tagName}: React is not present in payload`)
    if (!ReactDOM) throw new Error(`${this.tagName}: ReactDOM is not present in payload`)
    if (this.getAttribute('hydrate') == 'false') return
    if (!this.refs.topReact && !this.forceUpdateReact) {
      if (ReactDOMClient) {
        this.reactRoot ||= ReactDOMClient.createRoot(this.getReactRootElement())
        this.reactRoot.render(this.getReactElement())
      } else {
        ReactDOM.render(this.getReactElement(), this.getReactRootElement())
      }
    } else if (this.forceUpdateReact && this.connected) {
      this.forceUpdateReact?.()
    } else {
      this.refs.topReact?.forceUpdateReact?.()
    }
  }

  invokeReactRendering(): ReactNode {
    const { React, ReactDOM } = (this.payload as ReactPayload) || {}
    if (!this.connected) return null
    const children = this.descendants
      .filter((r) => {
        return r.refs.parentReact == this && r.payload && r.connected
      })
      .map((child: FEAASElement<any, any>, index) => {
        return this.getReactElement(child)
      })
    return this.render({ ...this.props, children }, this.payload)
  }

  reactWrapper: React.FunctionComponent
  reactErrorBoundary: new (...args: any) => React.Component<{ children: any; slot: string }, { error: Error }>
  reactRoot: ReactDOMClient.Root

  getReactErrorBoundary(target: FEAASElement = this, displayName = target.tagName) {
    if (this.reactErrorBoundary) return this.reactErrorBoundary

    const { React, ReactDOM } = (target.payload as ReactPayload) || {}
    return (this.reactErrorBoundary ||= class FEAASErrorBoundary extends React.Component<
      { children: any; slot: string },
      { error: Error }
    > {
      state = {
        error: null as Error
      }

      static getDerivedStateFromError(error: Error) {
        return { error: error }
      }

      componentDidCatch(error: Error, errorInfo: any) {
        console.error(`FEAAS: ${target.tagName} Error:`, error, errorInfo)
        target.onError(error, 'react')
        //this.setState({ error: error })
      }

      render() {
        if (this.state.error) {
          // target.classList.add('feaas-error')
          return
          //return <h1>There seems to be a problem.</h1>
        }
        //if (target.classList.contains('feaas-error')) target.classList.remove('feaas-error')

        return this.props.children
      }
    })
  }

  getReactElement(target: FEAASElement = this, props?: any) {
    const { React, ReactDOM } = (target.payload as ReactPayload) || {}
    const ErrorBoundary = this.getReactErrorBoundary()
    const Wrapper = this.getReactWrapper(target)
    return (
      // @ts-ignore TS5 react types throws here
      <ErrorBoundary key={target.tagName + '-' + target.getUID()} slot={target.getAttribute('slot')}>
        <Wrapper />
      </ErrorBoundary>
    )
  }

  getReactWrapper(target: FEAASElement = this, displayName = target.tagName) {
    if (target.reactWrapper) return target.reactWrapper

    const { React, ReactDOM } = (target.payload as ReactPayload) || {}

    target.reactWrapper = (props?: any) => {
      if (target.render) {
        const [value, forceUpdateReact] = React.useReducer((r) => r + 1, 0)
        target.forceUpdateReact = forceUpdateReact
        return ReactDOM.createPortal(<>{target.invokeReactRendering()}</>, target.getReactRootElement())
      } else {
        return target.update()
      }
    }

    Object.assign(target.reactWrapper, {
      key: target.tagName + '-' + target.getUID(),
      displayName: displayName
    })

    return target.reactWrapper
  }

  uid: string
  getUID() {
    return (this.uid ||= String(Math.random()))
  }
  forceUpdateReact: (node?: ReactNode) => void

  /**
   * Render method that is pretty much the same as React Functional component, but with extra second argument for
   * payload (usually result of import, it is expected to have React in scope).
   */
  render?(props: React.PropsWithChildren<Props>, payload: Payload): ReactNode | ReactElement

  useShadowRoot: boolean
  getShadowRoot() {
    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' })
    }
    return this.shadowRoot
  }
  getReactRootElement(): HTMLElement | ShadowRoot {
    return this.useShadowRoot ? this.getShadowRoot() : this.getRoot()
  }

  /** Set props and return values on new next tick */
  set(overrides?: Partial<Props>) {
    try {
      this.scheduleRender()
      this.overrides = {
        ...this.overrides,
        ...overrides
      }
      this.props = {
        ...this.getProps(),
        ...this.overrides
      }
      for (var property in this.props) {
        const isJSON = this.isJSONAttribute(property)
        const value = this.props[property]
        const valueAsString = isJSON ? JSON.stringify(value) : String(value)
        const defaultValue = this.defaultProps[property as keyof typeof this.defaultProps]
        const defaultValueAsString = isJSON ? JSON.stringify(defaultValue) : String(defaultValue)
        const attribute = property.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
        const oldValue = this.getAttribute(attribute)
        try {
          var odlValueAsString: string = isJSON ? JSON.stringify(JSON.parse(oldValue)) : oldValue
        } catch (e) {
          var odlValueAsString: string = null
        }

        if (value == null || valueAsString === defaultValueAsString) {
          this.removeAttribute(attribute)
        } else if (typeof value == 'string' || typeof value == 'number' || typeof value == 'boolean' || isJSON) {
          if (odlValueAsString != valueAsString) this.setAttribute(attribute, valueAsString)
        }
      }
    } catch (e) {
      this.whenRendered.reject(e)
      this.onError(e, 'set')
    }
    return this
  }

  import(path: string): Promise<Payload> {
    return import(/* @vite-ignore */ /* webpackIgnore: true */ path)
  }

  /** Schedule rendering on next tick, to batch multiple attribute changes */
  initialized: boolean
  whenRendered = ControlledPromise<this>(
    () => (this.initialized = true),
    () => this.cancelRender()
  )
  scheduleRender() {
    if (this.nextRender == null) {
      this.whenRendered = this.whenRendered.restart()
    }
    this.cancelRender()
    this.nextRender = requestAnimationFrame(() => {
      this.nextRender = null
      this.advance().catch((e) => {})
    })
  }

  cancelRender() {
    cancelAnimationFrame(this.nextRender)
  }

  formatURL(src: string, hostname: string) {
    if (typeof src == 'string' && src.match(/^\/[^\/]/)) {
      return hostname + src
    } else {
      return src
    }
  }

  getContextAttribute(name: string, element = this) {
    for (
      var p: HTMLElement = this;
      (p = p.parentElement || ((p.parentNode instanceof ShadowRoot ? p.parentNode.host : null) as HTMLElement));

    ) {
      if (p.getAttribute(name) != null) return p.getAttribute(name)
    }
    return this.getAttribute(name)
  }

  static register(tagName: string, win?: Window) {
    if (win == null) win = typeof window != 'undefined' ? window : null
    if (win && !win.customElements.get(tagName)) {
      win.customElements.define(tagName, this)
    }
  }
}

export { FEAASElement as Element }
export { WebComponent }
