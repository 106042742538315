import React from 'react'
import * as BYOC from '@sitecore/byoc/react'
import { HTMLNode, parseHTMLContent, renderHTMLContent } from '../dom/html.js'
import { DataSettings } from '../utils/settings.js'
import {
  FEAASCDNStylesheetParams,
  FEAASCDNStylesheetSource,
  FEAASComponentProps,
  fetchComponent,
  getComponentURL,
  getStylesheetSource,
  getStylesheetURL,
  parseComponentSource,
  parseStylesheetSource
} from './index.js'
export * from '../headless.js'
export { External, FEAASExternalPropsObject, getComponent, linkComponents, registerComponent } from './FEAASExternal.js'
export * from './index.js'
export { renderHTMLContent }

type FEEASReactComponentFetch = string[] | string | null | undefined | boolean
export function normalizeFetchAttribute(fetch: FEEASReactComponentFetch) {
  if (fetch == null || fetch === true) {
    return ['template', 'stylesheet', 'data']
  }
  return [].concat(fetch || [])
}

export type FEAASReactComponentProps = FEAASComponentProps & {
  fetch?: FEEASReactComponentFetch
  preload?: boolean
  lastModified?: Date | string
}

/**
 * A component to include a stylesheet using a <link> tag. Placing one in the HEAD ensures that there will be no flash
 * of unstyled content.
 *
 * @param {FEAASCDNStylesheetParams | FEAASCDNStylesheetSource} props  - The properties for including the stylesheet.
 * @returns {JSX.Element} The <link> element for the stylesheet.
 */
export const FEAASReactStylesheet = (props: FEAASCDNStylesheetParams | FEAASCDNStylesheetSource) => {
  if ('src' in props) {
    props = parseStylesheetSource(props.src)
  }
  return <link rel='stylesheet' href={getStylesheetSource(props)} />
}

/**
 * An asynchronous wrapper over FEAAS.Component for use in React, especially suited for Next.js.
 * This component fetches HTML and data asynchronously for server-side rendering with data mapping.
 *
 * @param {FEAASReactComponentProps} props  - The properties for the component.
 * @returns {Promise<JSX.Element>} The JSX element representing the component.
 */
export const FEAASReactServerComponent = async ({ data, fetch, ...props }: FEAASReactComponentProps) => {
  const fetchedData = await DataSettings.fetch(data || {})
  fetch = normalizeFetchAttribute(fetch ?? ['stylesheet'])

  const p: FEAASReactComponentProps = { ...props }

  if (('src' in props || 'component' in props) && props?.template == null) {
    const response = await fetchComponent(props)

    p.template = response.template
    p.lastModified = response.lastModified
  } else if (props?.template == null && !fetch.includes('template')) {
    fetch.push('template')
  }

  return FEAASReactComponent({
    ...p,
    data,
    fetch,
    fetchedData
  })
}
/**
 * A wrapper over FEAAS.Component for use in React. It converts a passed template HTML into React,
 * allowing external components in the render tree. Data can be provided in JSON format.
 *
 * @param {FEAASReactComponentProps} props  - The properties for the component.
 * @returns {JSX.Element} The JSX element representing the component.
 */
export function FEAASReactComponent({
  data,
  lastModified,
  fetchedData,
  ...props
}: FEAASReactComponentProps & { fetchedData?: any }) {
  let preload = null
  let children: null | React.ReactNode

  if ('template' in props && props.template) {
    children = parseHTMLContent(props.template, fetchedData || data || {}).map((node, index) =>
      HTMLNodeToReact(
        node,
        (node, attributes) => {
          if (node.localName == 'feaas-external') {
            const camelized = {} as any
            for (var prop in attributes) {
              const key = prop == 'data-external-id' ? 'componentName' : BYOC.toCamelCase(prop)
              camelized[key] = attributes[prop]
            }
            return [BYOC.Component, camelized]
          }
        },
        index
      )
    )
  }

  if ('src' in props) {
    props = { ...parseComponentSource(props.src), ...props }
  }
  const fetch = normalizeFetchAttribute(props.fetch)
  const embeddedData = fetch.includes('data') ? data : fetch.includes('template') ? fetchedData : null
  return (
    <>
      {'preload' in props && preload === true && 'src' in props && (
        <>
          <link rel='preload' as='fetch' href={getComponentURL(props.src)} crossOrigin='anonymous' />
          <link rel='preload' as='style' href={getStylesheetURL(props.src)} crossOrigin='anonymous' />
        </>
      )}
      <feaas-component
        class='-feaas'
        {...props}
        last-modified={lastModified ? String(lastModified) : null}
        fetch={props.fetch == null ? props.fetch : ([].concat(props.fetch).join(' ') as any)}
        suppressHydrationWarning
        template={null}
        data={typeof embeddedData == 'string' ? embeddedData : embeddedData ? JSON.stringify(embeddedData) : null}
      >
        {children}
      </feaas-component>
    </>
  )
}

/**
 * Converts an HTMLNode into a React tree, ensuring proper context access for external components.
 *
 * @param {HTMLNode} node        - The HTML node to be converted.
 * @param {any}      [callback]  - Callback to customize turning of HTML node into react tree.
 * @param {any}      [key]       - The key for the React element.
 * @returns {React.ReactNode} The resulting React node.
 */
export function HTMLNodeToReact(
  node: HTMLNode,
  callback?: (node: HTMLNode, attributes: Record<string, any>) => [tagName: any, attributes: Record<string, any>],
  key?: any
): React.ReactNode {
  if (node.nodeType === 3) {
    return node.textContent
  }
  const children = Array.from(node.childNodes).map((child, index) => HTMLNodeToReact(child, callback, index))

  const attributes: { [key: string]: string | Record<string, string> } = {}
  for (let i = 0; i < node.attributes.length; i++) {
    const { name, value } = node.attributes[i]
    if (name == 'style') {
      attributes[name] = String(value || '')
        .trim()
        .split(/\s*;\s*/)
        .reduce((object, pair) => {
          const [k, v] = pair.split(':')
          return {
            ...object,
            [BYOC.toCamelCase(k)]: v
          }
        }, {})
    } else if (name == 'class') {
      attributes.className = value
    } else {
      attributes[name] = value
    }
  }

  const reactNode = callback?.(node, attributes)
  const reactName = reactNode?.[0] || node.nodeName.toLowerCase()
  const reactAttributes = Object.assign({ key, suppressHydrationWarning: true }, reactNode?.[1] || attributes)
  return React.createElement(reactName, reactAttributes, ['img', 'br', 'input'].includes(reactName) ? null : children)
}

export type ExternalComponentProps = BYOC.ComponentProps
export type ContextProperties = BYOC.ContextProperties
export const enableNextClientsideComponents = BYOC.enableNextClientsideComponents
export const setContextProperties = BYOC.setContextProperties
export const ExternalComponentBundle = BYOC.Bundle
export const ExternalComponent = BYOC.Component
export const ReactExternalComponent = BYOC.RegularComponent
export const NextExternalComponent = BYOC.NextComponent
export const Component = FEAASReactComponent
export const ServerComponent = FEAASReactServerComponent
export const Stylesheet = FEAASReactStylesheet
