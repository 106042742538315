/** Lightweight DOM parser and serializer to use in server environments */

import { DataScopes } from '../utils/settings.js'
import { FEAASCustomizations, renderDOMElement } from './rendering.js'

export type HTMLAttribute = { name: string; value: string }

export class HTMLNode {
  nodeName: string
  nodeType: number
  textContent?: string
  attributes: HTMLAttribute[]
  childNodes: HTMLNode[]
  parentNode?: HTMLNode

  constructor(nodeName: string, nodeType: number = 1, textContent?: string) {
    this.nodeName = nodeName
    this.nodeType = nodeType
    this.textContent = textContent
    this.attributes = []
    this.childNodes = []
  }

  setAttribute(name: string, value: string) {
    const attr = this.attributes.find((attr) => attr.name === name)
    if (attr) {
      attr.value = value
    } else {
      this.attributes.push({ name, value })
    }
  }

  getAttribute(name: string): string | undefined {
    const attr = this.attributes.find((attr) => attr.name === name)
    return attr ? attr.value : undefined
  }

  insertBefore(newNode: HTMLNode, referenceNode: HTMLNode | null) {
    newNode.parentNode = this
    if (referenceNode === null) {
      this.childNodes.push(newNode)
    } else {
      const index = this.childNodes.indexOf(referenceNode)
      if (index !== -1) {
        this.childNodes.splice(index, 0, newNode)
      }
    }
  }

  removeAttribute(attrName: string) {
    const index = this.attributes.findIndex((attr) => attr.name === attrName)
    if (index !== -1) {
      this.attributes.splice(index, 1)
    }
  }

  appendChild(newNode: HTMLNode) {
    newNode.parentNode = this
    this.childNodes.push(newNode)
  }

  get tagName() {
    return this.nodeName?.toUpperCase()
  }

  get localName() {
    return this.nodeName?.toLowerCase()
  }

  get innerHTML() {
    return serializeHTML(this.childNodes)
  }
  set innerHTML(html: string) {
    this.childNodes = parseHTML(String(html ?? ''))
  }
  get outerHTML() {
    return serializeHTML([this])
  }
  get children() {
    return this.childNodes.filter((c) => c.nodeType == 1)
  }

  closest(selector: string): HTMLNode | null {
    let currentNode: HTMLNode | undefined = this

    while (currentNode) {
      if (selectorMatchesNode(selector, currentNode)) {
        return currentNode
      }

      currentNode = currentNode.parentNode
    }

    return null
  }

  querySelectorAll(selector: string): HTMLNode[] {
    const selectors = selector.split(/\s*,\s*/)
    const results: HTMLNode[] = []

    const checkNode = (node: HTMLNode) => {
      for (const sel of selectors) {
        if (selectorMatchesNode(sel, node)) {
          results.push(node)
          break
        }
      }

      for (const child of node.childNodes) {
        checkNode(child)
      }
    }

    for (const child of this.childNodes) {
      checkNode(child)
    }

    return results
  }

  querySelector(selector: string): HTMLNode {
    return this.querySelectorAll(selector)[0]
  }

  private findSibling(step: -1 | 1, elementTypeOnly?: boolean): HTMLNode | null {
    if (!this.parentNode) return null
    const siblings = this.parentNode.childNodes
    const index = siblings.indexOf(this)
    if (index === -1) return null

    for (let i = index + step; i >= 0 && i < siblings.length; i += step) {
      const sibling = siblings[i]
      if (!elementTypeOnly || sibling.nodeType === 1) {
        return sibling
      }
    }

    return null
  }

  removeChild(child: HTMLNode): HTMLNode {
    const index = this.childNodes.indexOf(child)
    if (index === -1) return
    this.childNodes.splice(index, 1)
    child.parentNode = undefined
    return child
  }

  get previousSibling(): HTMLNode | null {
    return this.findSibling(-1)
  }

  get nextSibling(): HTMLNode | null {
    return this.findSibling(1)
  }

  get previousElementSibling(): HTMLNode | null {
    return this.findSibling(-1, true)
  }

  get nextElementSibling(): HTMLNode | null {
    return this.findSibling(1, true)
  }
  cloneNode(deep: boolean = false): HTMLNode {
    const clonedNode = new HTMLNode(this.nodeName, this.nodeType)
    clonedNode.attributes = JSON.parse(JSON.stringify(this.attributes))

    if (this.textContent) {
      clonedNode.textContent = this.textContent
    }

    if (deep) {
      for (const childNode of this.childNodes) {
        const clonedChild = childNode.cloneNode(true)
        clonedNode.appendChild(clonedChild)
      }
    }

    return clonedNode
  }
  get parentElement() {
    return this.parentNode?.nodeType == 1 ? this.parentNode : null
  }

  ownerDocument = {
    createElement(name: string): HTMLNode {
      return new HTMLNode(name)
    }
  }
}

function selectorMatchesNode(selector: string, node: HTMLNode): boolean {
  const simpleSelectors = selector.match(/([.#]?[\w-]+|\[([\w-]+)(="([^"]*)")?\])/g)
  if (!simpleSelectors) {
    return false
  }

  for (const simpleSelector of simpleSelectors) {
    if (simpleSelector.startsWith('#')) {
      if (node.getAttribute('id') !== simpleSelector.slice(1)) {
        return false
      }
    } else if (simpleSelector.startsWith('.')) {
      const classNames = (node.getAttribute('class') || '').split(' ')
      if (!classNames.includes(simpleSelector.slice(1))) {
        return false
      }
    } else if (simpleSelector.startsWith('[')) {
      const attrSelectorMatch = simpleSelector.match(/^\[([\w-]+)(="([^"]*)")?\]$/)
      if (attrSelectorMatch) {
        const attrName = attrSelectorMatch[1]
        const attrValue = attrSelectorMatch[3]
        const actualValue = node.getAttribute(attrName)

        if (typeof attrValue === 'undefined') {
          if (actualValue === undefined) {
            return false
          }
        } else {
          if (actualValue !== attrValue) {
            return false
          }
        }
      } else {
        return false
      }
    } else {
      if (node.nodeName !== simpleSelector) {
        return false
      }
    }
  }

  return true
}

export function parseHTML(html: string): HTMLNode[] {
  const nodes: HTMLNode[] = []
  const stack: HTMLNode[] = []

  while (html) {
    const openTagMatch = html.match(/^<([\w-]+)(\s[^>]*)?>/)
    const closingTagMatch = html.match(/^<\/([\w-]+)>/)

    if (openTagMatch) {
      const nodeName = openTagMatch[1].toLowerCase()
      const attrsStr = openTagMatch[2] || ''
      const attrs =
        attrsStr.match(/([\w-]+)(="([^"]*)")?/g)?.map((attr) => {
          const [_, name, , value] = attr.match(/([\w-]+)(="([^"]*)")?/)
          return { name, value: unescapeHTML(value || '') }
        }) || []

      const node = new HTMLNode(nodeName, 1)
      node.attributes = attrs
      if (stack.length > 0) {
        const parentNode = stack[stack.length - 1]
        parentNode.childNodes.push(node)
        node.parentNode = parentNode
      } else {
        nodes.push(node)
      }
      if (!['img', 'input', 'br'].includes(nodeName)) stack.push(node)
      html = html.slice(openTagMatch[0].length)
    } else if (closingTagMatch) {
      stack.pop()
      html = html.slice(closingTagMatch[0].length)
    } else {
      const textEnd = html.indexOf('<')
      const textContent = html.slice(0, textEnd !== -1 ? textEnd : undefined)
      const textNode = new HTMLNode('#text', 3)
      textNode.textContent = textContent
      if (stack.length > 0) {
        const parentNode = stack[stack.length - 1]
        parentNode.childNodes.push(textNode)
        textNode.parentNode = parentNode
      } else {
        nodes.push(textNode)
      }
      html = html.slice(textContent.length)
    }
  }

  return nodes
}

export function escapeHTML(input: string): string {
  return input
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#x27;')
}

function unescapeHTML(input: string): string {
  return input
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#x27;/g, "'")
}

export function serializeHTML(nodes: HTMLNode[]): string {
  return nodes
    .map((node) => {
      if (node.nodeType === 3) {
        return node.textContent || ''
      }

      const { nodeName, attributes } = node
      const serializedAttributes = attributes
        .map(({ name, value }) => ` ${name}="${escapeHTML(String(value))}"`)
        .join('')
      const serializedChildren = serializeHTML(node.childNodes)
      if (['img', 'input', 'br'].includes(nodeName)) {
        return `<${nodeName}${serializedAttributes} />`
      } else {
        return `<${nodeName}${serializedAttributes}>${serializedChildren}</${nodeName}>`
      }
    })
    .join('')
}

export function renderHTMLContent(template: string, data?: DataScopes, config?: FEAASCustomizations) {
  return parseHTMLContent(template, data, config)[0].outerHTML
}

export function parseHTMLContent(template: string, data?: DataScopes, config?: FEAASCustomizations) {
  const newTemplate = config?.processTemplate?.(null, template, data) || template
  const nodes = parseHTML(newTemplate)
  return nodes.map((node) => {
    return renderDOMElement(node as unknown as HTMLElement, data, config)
  }) as unknown as HTMLNode[]
}
