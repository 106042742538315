import * as BYOC from '@sitecore/byoc'
import React from 'react'
import ReactDOM from 'react-dom'
import { FEAASElement } from '../components/FEAASElement.js'
import { ReactJSX, ReactModules } from '../components/FEAASReactElement.js'
import { Component, FEAASComponentProps } from './react.js'

export const FEAASExternalPropsObject = BYOC.getComponentProperties

export function FEAASExternalProps(element: FEAASElement): any {
  const props = BYOC.objectKeysToCamelCase(
    Array.from(element.attributes).reduce((prev, { name, value }) => {
      return Object.assign(prev, { [name]: value })
    }, {} as any)
  )
  return FEAASExternalPropsObject(props.dataExternalId, props)
}

export type FEAASExternalProps = ReturnType<typeof FEAASExternalProps> & { dataExternalId: string }

export default class FEAASExternal extends FEAASElement<FEAASExternalProps, ReactModules> {
  load() {
    return Promise.resolve({ React, ReactDOM })
  }

  update() {
    const { dataExternalId: id } = this.props
    const Component = BYOC.getComponent(id)?.component
    if (!Component && this.getAttribute('hydrate') == 'false') return
    return super.update()
  }

  render(props: FEAASExternalProps, { React }: ReactModules): ReactJSX {
    const { dataExternalId: id } = props
    console.log(props)
    const Component = BYOC.getComponent(id)?.component
    if (!Component) {
      console.warn(`FEAASExternal: \`${id}\` is not registered`)
    }
    return <>{Component && <Component {...props} />}</>
  }

  getProps() {
    return FEAASExternalProps(this)
  }

  static Preview(props: Partial<FEAASComponentProps<any>> & { src: string }) {
    const src =
      props?.src || (typeof window !== 'undefined' ? window.location.search.match(/feaasSrc=([^&]+)/)?.[1] : null)
    return src ? <Component src={src} {...props} /> : null
  }

  static linkComponents(components: Promise<any>[]): () => any {
    return () => components.map((components) => null)
  }

  static registered: BYOC.RegisteredComponents = BYOC.registered
  static registerComponent = BYOC.registerComponent
  static setRegistrationCallback = BYOC.setRegistrationCallback
  static getComponent = BYOC.getComponent
}

FEAASExternal.register('feaas-external')

export const { getComponent, linkComponents, registerComponent } = FEAASExternal
export { FEAASExternal as External }
