import { ControlledPromise } from './utils/promise.js'

const phases = ['initial', 'check', 'updated'] as const
export type ValidatableRequestPhase = (typeof phases)[number]
export type FetchCache = Record<string, FetchCacheRequest>
export interface ValidatableRequest {
  url: Parameters<typeof fetch>[0]
  options?: Parameters<typeof fetch>[1]
  validator?: (resposne: Response, isCached: boolean) => boolean
  callback?: (response: Response, status: ValidatableRequestPhase, isCached: boolean) => any
  isCached?: boolean
  response?: Response
  cache?: FetchCache
}

export type FetchCacheRequest = {
  phase: ValidatableRequestPhase
  initial: ControlledPromise<Response>
  check: ControlledPromise<Response>
  updated: ControlledPromise<Response>
  final: ControlledPromise<Response>
}

export var fetchCache: Record<string, FetchCacheRequest> = {}
export function clearFetchCache() {
  fetchCache = {}
}

/**
 * Utility to cache-bust static files. It relies on reading Date header to detect cached response, so it requires CORS
 * settings to be set.
 *
 * It accepts validator function that determines if response is usable. If response was pulled from cache and is valid,
 * the optional callback function is fired right away. Then HEAD request is made bypassing the cache, to see if the
 * response has an newer Last-Modified header. In case it does and it passes validation, third request is fired that
 * actually fetches the new content and callback is invoked the second time.
 *
 * It works as promise, but also accepts callback to use cached blobs mid-flight and have access to headers. Any network
 * errors will not be caught. Function throws when unable to fetch valid response.
 */
export async function fetchAndRevalidate(
  url: ValidatableRequest['url'],
  options?: ValidatableRequest['options'],
  validator?: ValidatableRequest['validator'],
  callback?: ValidatableRequest['callback'],
  cache: FetchCache = fetchCache
) {
  // make request as usual, see if there's cached version of an asset
  return fetchWithCacheAwareness({ url, options, validator, callback, cache })
    .then(fetchToValidateCache)
    .then((result) => result.response)
}

/**
 * Executes a fetch request optionally validating and handling the response. Using callback function it's possible to
 * observe successful response twice: Cached version first, and up-to-date later.
 *
 * @param {Object}                  request              - An object containing request parameters.
 * @param {string}                  request.url          - The URL to fetch.
 * @param {Object}                  request.options      - Fetch request options.
 * @param {Function}                [request.validator]  - Function to validate the response. Should return `false` if
 *                                                       validation fails.
 * @param {Function}                [request.callback]   - Function to execute after fetching. Receives the response,
 *                                                       phase, and cache status.
 * @param {Object}                  [request.cache]      - Cache object to store or retrieve cached data.
 * @param {ValidatableRequestPhase} [phase='initial']    - The phase of the request, defaults to 'initial'.
 * @returns {Promise<Object>} A promise that resolves to an object containing request details and the response.
 * @throws {Error} Throws an error if the fetch fails or if the response is not valid according to the validator.
 */
export async function fetchWithCacheAwareness(
  { url, options, validator, callback, cache }: ValidatableRequest,
  phase: ValidatableRequestPhase = 'initial'
) {
  const date = new Date()
  if (cache != null) {
    var cachedRequest = getFetchCache(url)
    if (phases.indexOf(cachedRequest.phase) >= phases.indexOf(phase)) {
      var promise = cachedRequest[phase]
    } else {
      cachedRequest.phase = phase
    }
  }
  const response = await (promise || fetch(url, options))
  if (!promise && cachedRequest) {
    if (!response.ok) {
      cachedRequest[phase].reject(new Error('Fetch failed'))
    } else {
      cachedRequest[phase].resolve(response)
    }
  }
  if (!response.ok) throw new Error('Fetch failed')
  // see if response was pulled from cache
  const isCached =
    new Date(response.headers.get('date')) < date ||
    (promise && phase == 'initial' && (cachedRequest.phase == 'updated' || cachedRequest.phase == 'check'))
  if (validator?.(response, isCached) === false) throw new InvalidResponse('Response is not valid')
  await callback?.(response, phase, isCached)
  return {
    url,
    options,
    isCached,
    response,
    validator,
    callback,
    cache
  }
}

export function getFetchCache(url: Parameters<typeof fetch>[0], dontCreate = false) {
  const key = String(url).replace('?head', '')
  var cache = fetchCache[key]
  if (!cache && !dontCreate) {
    cache = fetchCache[key] = {
      phase: null,
      initial: ControlledPromise(),
      check: ControlledPromise(),
      updated: ControlledPromise(),
      final: ControlledPromise()
    }
  }
  return cache
}

export class InvalidResponse extends Error {}

/**
 * Validates cache of previousl fetch If the resource is cached and there is a newer version available, it will be
 * re-fetched.
 *
 * @param request  - The request object containing the URL, cache information, options, validator, and response of
 *                 initial request.
 * @returns A promise that resolves to the request object.
 */
export async function fetchToValidateCache(
  request: ValidatableRequest & Required<Pick<ValidatableRequest, 'response'>>
) {
  const { url, isCached, options, validator, response, cache } = request
  if (isCached) {
    // check if response was stale by sending quick HEAD request busting cache
    const { response: head } = await fetchWithCacheAwareness(
      {
        url: url + '?head',
        options: {
          ...options,
          method: 'HEAD',
          cache: 'no-cache'
        },
        validator,
        cache
      },
      'check'
    )

    // there's a newer version of the file that we may fetch
    if (head.headers.get('last-modified') != response.headers.get('last-modified')) {
      // the new resource has to be re-fetched
      return fetchBypassingCache(request)
    }
  }
  return request
}

export async function fetchBypassingCache(request: ValidatableRequest) {
  return fetchWithCacheAwareness(
    {
      ...request,
      options: {
        ...request.options,
        cache: 'reload'
      }
    },
    'updated'
  )
}
