import {
  fetchAndRevalidate,
  fetchWithCacheAwareness,
  fetchBypassingCache,
  fetchToValidateCache,
  ValidatableRequestPhase
} from './fetch.js'

export const DEFAULT_CDN_URL = `https://feaas.blob.core.windows.net`
export type FEAASCDNComponentParams = {
  cdn?: string
  revision?: 'staged' | 'published' | 'saved' | number
  library: string
  component: string
  version?: string

  /**
   * @deprecated Use `cdn` instead.
   */
  hostname?: string
}
export type FEAASCDNComponentSource = {
  src: string
}
export type FEAASCDNComponentParamsCustomizations = {
  instance?: string
}
export type FEAASCDNComponentProps = (FEAASCDNComponentParams | FEAASCDNComponentSource) &
  FEAASCDNComponentParamsCustomizations

export interface FEAASCDNStylesheetParams {
  cdn?: string
  library: string
  revision?: 'staged' | 'published' | 'saved' | number

  /**
   * @deprecated Use `cdn` instead.
   */
  hostname?: string
}

export type FEAASCDNStylesheetSource = {
  src: string
}
export type FEAASCDNStylesheetProps = FEAASCDNStylesheetParams | FEAASCDNStylesheetSource | string

export function parseComponentSource(source: string): FEAASCDNComponentParams {
  const match = source.match(
    /^(?:(.*?)\/components)?\/?([^\/]+)\/([^\/]+)(?:\/([^\/]+)(?:\/(published|staged|saved|\d+)(?:\.html?)?)?)?$/
  )
  if (match) {
    var [, cdn, library, component, version, revision] = match
    return { cdn, library, component, version, revision: revision as any }
  }
}
export function getComponentSource(props: FEAASCDNComponentParams) {
  if (!props || !props.library || !props.component)
    throw new Error('Component requires `library` and `component` properties')
  return `${props.cdn && props.cdn != DEFAULT_CDN_URL ? `${props.cdn}/components` : ''}/${props.library}/${
    props.component
  }/${
    !props.version || (props.version == 'responsive' && (!props.revision || props.revision == 'published'))
      ? ''
      : props.version
  }/${!props.revision || props.revision == 'published' ? '' : props.revision}`.replace(
    /(^\/|\/$|\/\/)(?!.+\/components\/)/g,
    ''
  )
}

export function getStylesheetSource(props: FEAASCDNStylesheetParams) {
  return `${props.cdn && props.cdn != DEFAULT_CDN_URL ? `${props.cdn}/styles/` : ''}${props.library}/${
    !props.revision || props.revision == 'published' ? '' : props.revision
  }`.replace(/\/$|\/\/(?!.+\/styles\/)/g, '')
}

export function parseStylesheetSource(source: string): FEAASCDNStylesheetParams {
  const match = source.match(/^(?:(.*?)\/styles\/)?([^\/]+)(?:\/(published|staged|saved|\d+)(?:.css)?)?$/)
  if (match) {
    var [, cdn, library, revision] = match
    return { cdn, library, revision: revision as any }
    // try parsing it as component url
  } else {
    const parsed = parseComponentSource(source) as Record<string, string>
    if (parsed) {
      var { cdn, library, revision } = parsed
      if (revision?.match(/\d/)) revision = undefined
      return { cdn, library, revision: revision as any }
    }
  }
}
export function getComponentURL(props: FEAASCDNComponentProps | string) {
  if (typeof props == 'string') {
    props = { src: props }
  }
  if ('src' in props) {
    props = {
      ...parseComponentSource(props.src)
    }
  }
  return `${props.cdn || props.hostname || DEFAULT_CDN_URL}/components/${props.library}/${props.component}/${
    props.version || 'responsive'
  }/${props.revision || 'published'}.html`
}

export function getStylesheetURL(props: FEAASCDNStylesheetProps) {
  if (typeof props == 'string') {
    props = { src: props }
  }
  if ('src' in props) {
    props = {
      ...parseStylesheetSource(props.src)
    }
  }
  return `${props.cdn || props.hostname || DEFAULT_CDN_URL}/styles/${props.library}/${
    props.revision == 'staged' ? 'staged' : props.revision == 'saved' ? 'saved' : 'published'
  }.css`
}

export async function fetchAndRevalidateComponent(
  props: FEAASCDNComponentProps,
  callback?: (html: string) => void,
  dontRevalidate = false
) {
  const onlyIfNotDeleted = (response: Response) => {
    return response.headers.get('x-ms-meta-versionDeletedAt') == null
  }
  const parseResponse = (response: Response) => {
    return response.clone().text().then(callback)
  }

  // Fetch two requests in parallel: One optimistically checking if component was forked,
  // and another checking original definition of a component
  const results = await Promise.all([
    props.instance &&
      fetchWithCacheAwareness({
        options: {
          headers: {
            Accept: 'text/html'
          },
          credentials: 'omit'
        },
        url: getComponentURL({ ...props, version: props.instance }),
        validator: onlyIfNotDeleted
      }).catch((e) => e as Error),
    fetchWithCacheAwareness({ url: getComponentURL(props), validator: onlyIfNotDeleted }).catch((e) => e as Error)
  ])
  const result = results.find((result) => result && 'isCached' in result && result)
  if (!result || result instanceof Error) {
    const error = results.find((result) => result && result instanceof Error)
    throw error || new Error('Can not fetch component')
  }
  if (results[0] == result) {
    console.log('Using fork!', results)
  }
  await parseResponse(result.response)
  if (dontRevalidate) return result
  return fetchToValidateCache({ ...result, callback: parseResponse }).catch((e) => {
    if (results[0] == result) {
      fetchBypassingCache({ ...result, callback: null, validator: null })
      console.log('Fork was deleted')
      // forked version was cached, but it's now deleted
      if (!(results[1] instanceof Error)) {
        parseResponse(results[1].response)
        return fetchToValidateCache({ ...results[1], callback: parseResponse })
      }
    }
    throw e
  })
}

/** Fetches HTML of a component. */
export async function fetchComponent(props: FEAASCDNComponentProps | string) {
  if (typeof props == 'string') props = { src: props }
  var template: string
  const { response } = await fetchAndRevalidateComponent(
    props,
    (result) => {
      template = result
    },
    true
  )
  return { ...props, template, lastModified: response.headers.get('last-modified') }
}

export type ValidatableStylesheetCallback = (css: string, phase: ValidatableRequestPhase) => void
export async function fetchAndRevalidateStylesheet(
  props: FEAASCDNStylesheetProps,
  callback: ValidatableStylesheetCallback
) {
  if (typeof props == 'string') props = { src: props }
  return fetchAndRevalidate(
    getStylesheetURL(props),
    {
      headers: {
        Accept: 'text/css'
      },
      credentials: 'omit'
    },
    (response) => {
      return response.headers.get('x-ms-meta-deletedAt') == null
    },
    (response, phase) => {
      return response
        .clone()
        .text()
        .then((text) => callback(text, phase))
    }
  )
}
